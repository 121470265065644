import React from 'react'
import PropTypes from 'prop-types'

const Header = (props) => (
    <header id="header" style={props.timeout ? {display: 'none'} : {}}>
        <div className="content">
            <div className="inner">
                <h1>Juke Music Belfast</h1>
                <p>JUKE, verb. To dance suggestively.</p>
            </div>
        </div>
        <nav>
            <ul>
                    <li><a role="button" tabIndex="0" onClick={() => {props.onOpenArticle('about')}} onKeyPress={() => {props.onOpenArticle('about')}}>About</a></li>
                    <li><a role="button" tabIndex="0" onClick={() => {props.onOpenArticle('listen')}} onKeyPress={() => {props.onOpenArticle('listen')}}>Listen</a></li>
                    <li><a role="button" tabIndex="0" onClick={() => {props.onOpenArticle('contact')}} onKeyPress={() => {props.onOpenArticle('contact')}}>Contact</a></li>
            </ul>
        </nav>
    </header>
)

Header.propTypes = {
    onOpenArticle: PropTypes.func,
    timeout: PropTypes.bool
}

export default Header
